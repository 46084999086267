@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --trend-accent: #FF6F61;
    --button-primary: #1F75FE;
    --button-hover: #155ABF;
    --background-main: #0B0C10;
    --background-secondary: #1A1B1F;
    --text-primary: #FFFFFF;
    --text-secondary: #F5F5F5;
}


.fade-in-up {
    opacity: 0;
    transform: translateY(40px);
    animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}